import { useAuth } from 'context/AuthContext';
import { BotIcon, PencilIcon } from 'lucide-react';
import { useSupportBot } from '../hooks/useSupportBot';

export const SupportBotButton = ({
  chat_id,
  onNextMessage,
  currentMessage,
}: {
  chat_id: string;
  onNextMessage: (message: string) => void;
  currentMessage: string;
}) => {
  const { getNextMessageMutation, rewordMessageMutation } = useSupportBot({
    onNextMessage,
  });
  const { userDoc } = useAuth();
  if (!userDoc?.roles?.admin) return null;
  return (
    <>
      <button
        type="button"
        className="flex aspect-square h-[4rem] flex-col items-center justify-center rounded-full border-2 bg-black p-1 text-white transition-colors"
        onClick={() => {
          rewordMessageMutation.mutate(currentMessage);
        }}
      >
        {rewordMessageMutation.isPending ? (
          <div className="flex h-[2rem] w-[2rem] items-center justify-center gap-5">
            <div className="h-[2rem] w-[2rem] animate-spin rounded-full border-2 border-t-transparent" />
          </div>
        ) : (
          <PencilIcon />
        )}
      </button>
      <button
        type="button"
        className="flex aspect-square h-[4rem] flex-col items-center justify-center rounded-full border-2 bg-brand-secondary text-white transition-colors"
        onClick={() => {
          getNextMessageMutation.mutate(chat_id);
        }}
      >
        {getNextMessageMutation.isPending ? (
          // do 3 dots
          <div className="flex h-[2rem] w-[2rem] items-center justify-center gap-5">
            <div className="h-[2rem] w-[2rem] animate-spin rounded-full border-2 border-t-transparent" />
          </div>
        ) : (
          <BotIcon />
        )}
      </button>
    </>
  );
};
