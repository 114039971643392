import { useMutation } from '@tanstack/react-query';
import { useToastContext } from 'context/ToastContext';

export const useSupportBot = ({
  onNextMessage,
}: {
  onNextMessage: (message: string) => void;
}) => {
  const { showErrorToast } = useToastContext();
  const getURL = (endpoint: 'getNextMessage' | 'rewordMessage') => {
    if (process.env.NODE_ENV === 'development') {
      if (process.env.NEXT_PUBLIC_IS_STAGING) {
        return `http://127.0.0.1:5001/mx-locker-staging/us-central1/support-${endpoint}`;
      }
      return `http://127.0.0.1:5001/mxlocker-b1d4a/us-central1/support-${endpoint}`;
    } else if (process.env.NEXT_PUBLIC_IS_STAGING) {
      return `https://us-central1-mx-locker-staging.cloudfunctions.net/support-${endpoint}`;
    }
    return `https://us-central1-mxlocker-b1d4a.cloudfunctions.net/support-${endpoint}`;
  };
  const getNextMessageMutation = useMutation({
    mutationFn: async (chat_id: string) => {
      const response = await fetch(getURL('getNextMessage'), {
        method: 'POST',
        body: JSON.stringify({ chat_id }),
      });
      const json = (await response.json()) as {
        message: string;
        request_id: string;
      };
      return json;
    },
    onSuccess: (data) => {
      onNextMessage(data.message);
    },
    onError: (error) => {
      showErrorToast(error.message);
    },
  });
  const rewordMessageMutation = useMutation({
    mutationFn: async (message: string) => {
      const response = await fetch(getURL('rewordMessage'), {
        method: 'POST',
        body: JSON.stringify({ message }),
      });
      const json = (await response.json()) as {
        message: string;
      };
      return json;
    },
    onSuccess: (data) => {
      onNextMessage(data.message);
    },
    onError: (error) => {
      showErrorToast(error.message);
    },
  });
  return { getNextMessageMutation, rewordMessageMutation };
};
